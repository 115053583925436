import Main from './component/Main'
import './App.css'

function App() {
  return (
    <div className='app-wrapper'>
      <Main />
    </div>
  )
}

export default App
